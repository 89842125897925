import React,  {Component} from 'react';
import { NavLink } from 'react-router-dom';
import Logo from "./../assets/Logo.png";

import "./NavBar.css";

class NavBar extends Component {
    componentDidMount() {
        // Add an event listener to window scroll when the component mounts
        window.addEventListener("scroll", this.stickyHeader);
    }

    componentWillUnmount() {
        // Remove the event listener when the component unmounts to prevent memory leaks
        window.removeEventListener("scroll", this.stickyHeader);
    }

    stickyHeader = () => {
        const header = document.getElementById("myHeader");

        if (header) {
            const sticky = header.offsetTop;

            if (window.scrollY > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    };
    render() {
        return (
            <div className="header" id="myHeader">
                <nav className="navigation">
                    <div className="nav-items">
                        <ul>
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                {/* <NavLink to="/events">Events</NavLink> */}
                                <NavLink to="/solution">Solution</NavLink>
                            </li>
                            <li>
                                <div className="logo-ch-bros">
                                    <img src={Logo} alt="ChiochettiBros Logo" />
                                </div>
                            </li>
                            <li>
                                <NavLink to="/about">About</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
};

export default NavBar;
