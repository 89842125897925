import React from 'react'; 
import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import NavBar from './Components/NavBar';
import Footer from './Components/Footer.jsx';
import MobileNavBar from './Components/MobileNavBar';
import { Solution } from './Pages/Solution';

import './config/firebase-config.js'

const Home = lazy(() => import('./Pages/Home'));
const Events = lazy(() => import('./Pages/Events'));
const EventDetails = lazy(() => import('./Pages/EventDetails'));
const About = lazy(() => import('./Pages/About'));
const Contact = lazy(() => import('./Pages/Contact'));
const NoMatch = lazy(() => import('./Components/NoMatch'));
const Impressum = lazy(() => import('./Components/Impressum'));




const App = () => {


	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)'});

	return (
		<div className='container'>
			<main className='main-content'>
				{ isDesktopOrLaptop ? 
					<NavBar /> : 
					<MobileNavBar /> }
				<Suspense fallback={<div className="container">Loading...</div>}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/solution" element={<Solution />} />
						<Route path="/events" element={<Events />} />
						<Route path="/events/:slug" element={<EventDetails />} />
						<Route path="/impressum" element={<Impressum />} />
						<Route path="*" element={<NoMatch />} />
					</Routes>
				</Suspense>
				<Footer />
			</main>
		</div>
	);
};

export default App;
