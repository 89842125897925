// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOgEcp4TZra40RLAaMND8OwKkxf6ejGJg",
  authDomain: "chiochettibroswebsite.firebaseapp.com",
  projectId: "chiochettibroswebsite",
  storageBucket: "chiochettibroswebsite.appspot.com",
  messagingSenderId: "437912211336",
  appId: "1:437912211336:web:4687e3831ca2d11f64b43d",
  measurementId: "G-EFBPNFF2MM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);