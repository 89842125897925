import React from 'react'

import './Solution.css'

export const Solution = () => {

  return (
    <div className='solution-container'>
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSYaueq-QNHebpR3wJZUfNRY__0lIJeV2upZvtR7cUaZUdEMcXRcC5tovNhHsMg3zScZduzippqIuma/embed?start=true&loop=true&delayms=5000" 
            frameborder="0" width="1440" height="839" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" title='slides'>
        </iframe>
    </div>
  )
}
